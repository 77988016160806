body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Itim"; /* Add this line */
}

.app-background {
  background: linear-gradient(to bottom, black, white);
  min-height: 100vh;
  width: 100%;
}

.section {
  padding: 5rem 1rem;
  min-height: 100vh;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .section {
    padding: 3rem 1rem;
  }
}

@media (max-width: 480px) {
  .section {
    padding: 2rem 1rem;
  }
}

/* Smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* Prevent horizontal overflow */
#root {
  overflow-x: hidden;
  width: 100%;
}
